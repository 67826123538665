<template>
  <div class="Login">
    <div class="loginbox">
      <div class="message">安福电商货源管理系统</div>
      <div id="darkbannerwrap"></div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-input
          v-model="form.name"
          placeholder="请输入姓名"
          type="name"
        ></el-input>
        <el-input
          v-model="form.password"
          type="password"
          placeholder="密码(默认：123456)"
        ></el-input>

        <!-- <el-button :class="login_tatus ? 'renji' : ''" @click="renzheng"
          ><i class="el-icon-user-solid"></i>{{ text }}</el-button
        > -->
        <el-button class="logingo" type="primary" @click="login"
          >登录</el-button
        >
        <!-- <div
          style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px;"
        >
          推荐使用Microsoft Edge、Chrome浏览器
        </div> -->
        <div
          style="color: #666666; text-align: center; font-size: 12px;margin-top: 15px;"
        >
          <!-- 如有问题请联系编程老师19966745796（吴老师） -->
        </div>
      </el-form>
    </div>
    <div class="slidebox" v-if="slidebox_status">
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        slider-text="向右滑动"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
      ></slide-verify>
    </div>
    <div
      style="color: #ffffff; text-align: center; font-size: 12px;margin-top: 15px ;"
    >
      <!-- 网站所有权(吴*文)©桂ICP备2020007545号 -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      isPassing6: false,
      form: {
        name: "",
        password: "",
        tag: "1",
      },
      text: "点击人工验证", //滑块底部文字
      login_tatus: true,
      slidebox_status: false,
    };
  },
  methods: {
    renzheng() {
      this.slidebox_status = true;
    },
    onSuccess() {
      this.login_tatus = true;
      this.text = "认证成功！";
      this.slidebox_status = false;
    },
    onFail() {
      this.text = "认证失败,请重试";
    },
    onRefresh() {
      this.login_tatus = false;
      this.text = "点击人工验证";
    },
    login() {
      console.log(this.form);
      var that = this;
      if (!this.form.name || !this.form.password) {
        this.$message({
          message: "请先输入账号密码！",
          type: "warning",
        });
        return;
      }
      if (this.form.name == "admin" && this.form.password == "a6501026a") {
        sessionStorage.setItem("name", "admin");
        that.$router.push("/home/1");
      } else {
        that.$message({
          message: "账号密码错误！",
          type: "warning",
        });
        that.onRefresh();
        return;
      }

      // var url = this.$store.state.baseUrl + "/login";
      // this.$http
      //   .post(url, this.form)
      //   .then(function(response) {
      //     if (response.data.code == 404) {
      //       that.$message({
      //         message: "账号密码错误！",
      //         type: "warning",
      //       });
      //       that.onRefresh();
      //       return;
      //     }
      //     if (response.data.code == 200) {
      //       that.$Cookie.set("token", response.data.token, 1);
      //       response.data.result.password = that.form.password;
      //       that.$Cookie.set(
      //         "cookieUser",
      //         JSON.stringify(response.data.result),
      //         1
      //       );
      //       if (that.form.tag == "1") {
      //         that.$router.push("/stuhome");
      //       } else {
      //         that.$router.push("/techome");
      //       }
      //     }
      //   })
      //   .catch(function(error) {
      //     console.log(error);
      //     that.$message({
      //       message: "服务器异常！" + error,
      //       type: "warning",
      //     });
      //   });
    },
  },
  created() {
    let cookie = this.$Cookie.get("cookieUser");
    if (!cookie) {
      return;
    }
    let user = JSON.parse(cookie);
    let parm = {};
    parm.password = user.password;
    if (user.identity) {
      this.form.tag = "0";

      parm.name = user.name;
      parm.tag = "0";
    } else {
      this.form.tag = "1";
      parm.name = user.name;
      parm.tag = "1";
    }
    console.log(parm);
    var url = this.$store.state.baseUrl + "/login";
    var that = this;
    this.$http
      .post(url, parm)
      .then(function(response) {
        if (response.data.code == 404) {
          // that.$router.push("/");
        }
        if (response.data.code == 200) {
          if (user.identity) {
            that.$router.push("/techome");
          } else {
            that.$router.push("/stuhome");
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.Login {
  width: 100%;
  height: 100%;
  background: url(../assets/images/bg.png) no-repeat center;
  background-size: cover;
  overflow: hidden;
  .slidebox {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .el-button {
    margin-top: 15px;
    width: 100%;
    background-color: #189f92;
  }
  .renji {
    background-color: #0cd838;
    color: #fff;
  }
  .loginbox {
    margin: 120px auto 0 auto;
    min-height: 420px;
    max-width: 420px;
    padding: 40px;
    background-color: #eeeeee;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    /* overflow-x: hidden; */
    box-sizing: border-box;
    .message {
      font-size: 14px;
      margin: 10px 0 0 -58px;
      padding: 18px 10px 18px 60px;
      background: #189f92;
      position: relative;
      color: #fff;
      font-size: 16px;
    }

    #darkbannerwrap {
      background: url(../assets/images/aiwrap.png);
      width: 18px;
      height: 10px;
      margin: 0 0 20px -58px;
      position: relative;
    }
    .el-form {
      width: 100%;
      // background-color: red;
      .el-input {
        // border: 1px solid #dcdee0;
        vertical-align: middle;
        border-radius: 3px;
        height: 50px;
        // padding: 0px 16px;
        font-size: 12px;
        color: #555555;
        outline: none;
        width: 100%;
        box-sizing: border-box;
      }
      .el-select {
        width: 100%;
      }
      .logingo {
        color: #fff;
        padding: 0;
        background-color: #189f92;
        height: 40px;
        margin: 0;
        margin-top: 15px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Login {
    .loginbox {
      .message {
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
        padding: 18px 72px 18px 60px;
        background: #189f92;
        position: relative;
        color: #fff;
        font-size: 16px;
      }
      #darkbannerwrap {
        display: none;
      }
    }
  }
}
</style>
