<template>
  <div class="Page2">
    <div class="tt">
      <h1 class="name">安福电商货源</h1>
    </div>
    <header>
      <h3>货源管理</h3>
    </header>

    <div class="uploadbox">
      <div class="u2">
        <div>
          <div class="aa">
            <el-input placeholder="用户名" v-model="addname"> </el-input>
          </div>
        </div>
        <div class="aa">
          <el-input placeholder="用户资料" v-model="addtitle"> </el-input>
        </div>
        <div class="aa">
          <el-input placeholder="简介" v-model="jianjie"> </el-input>
        </div>
        <div class="aabb">
          <div class="aa0">
            <el-select v-model="addvip" placeholder="VIP等级">
              <el-option
                v-for="item in vipoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="aa1">
            <el-select v-model="value" placeholder="类别">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="aa2">
            <el-input placeholder="QQ" v-model="addqq"> </el-input>
          </div>
          <div class="aa2">
            <el-input placeholder="微信号" v-model="wxname"> </el-input>
          </div>
          <div class="aa2">
            <el-input placeholder="权重" v-model="llevel"> </el-input>
          </div>
        </div>
      </div>
      <div class="u1">
        <div class="tou">
          头像
        </div>
        <el-upload
          class="upload-demo"
          drag
          :action="baseUrl + '/api/album/upicon'"
          multiple
          :on-success="onupsuccess1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖到此处，或<em>上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <div class="u1">
        <div class="tou">
          微信
        </div>
        <el-upload
          class="upload-demo"
          drag
          :action="baseUrl + '/api/album/upwx'"
          multiple
          :on-success="onupsuccess2"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖到此处，或<em>上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <div class="u1">
        <div class="tou">
          微商
        </div>
        <el-upload
          class="upload-demo"
          drag
          :action="baseUrl + '/api/album/upws'"
          multiple
          :on-success="onupsuccess3"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖到此处，或<em>上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <div class="u22" style="">
        <div class="asd"></div>
        <el-button class="bt" type="primary" @click="addalbum">提交</el-button>
      </div>
    </div>

    <div class="listbox" v-for="(item, index) in list" :key="index">
      <div class="ll">
        <img
          :src="'https://huoyuan.ptfish.cn/storage/logo/' + item.logo"
          alt=""
        />
      </div>
      <div class="ll2">
        <div class="bb1">
          用户名：
          <div class="bb2">{{ item.name }}</div>
          <img class="licon" :src="item.licon" alt="" srcset="" />
          <div class="qz">
            权重：
            <el-input
              placeholder="请输入内容"
              v-model="item.llevel"
              @change="upqz(item)"
            >
            </el-input>
          </div>
        </div>

        <div class="bb1">
          类别：
          <div class="bb33">
            {{ item.category.name }}
          </div>
        </div>
        <div class="bb1">
          用户资料：
          <div class="bb3">
            {{ item.description }}
          </div>
        </div>
        <div class="qqwx">
          <div class="bb3">
            QQ：
            <div class="qqt">{{ item.cqq }}</div>
          </div>
          <div class="bb3">
            微信号：
            <div class="wxt">
              {{ item.wxname }}
            </div>
          </div>
        </div>

        <div class="bb">
          简介:<em> {{ item.jianjie }}</em>
        </div>
      </div>
      <div class="lll">
        <div>微信</div>
        <img
          :src="'https://huoyuan.ptfish.cn/storage/wx/' + item.cwx"
          alt=""
        />
      </div>
      <div class="lll">
        <div>微商</div>
        <img
          :src="'https://huoyuan.ptfish.cn/storage/ws/' + item.cws"
          alt=""
        />
      </div>
      <div class="ll3">
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          @click="delalbum(item.id)"
        ></el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Page2",
  components: {},
  data() {
    return {
      baseUrl: "",
      addname: "",
      addtitle: "",
      addvip: "",
      jianjie: "",
      addqq: "",
      logo: "",
      cwx: "",
      cws: "",
      llevel: "",
      wxname: "",
      list: [],
      vipoptions: [
        {
          value: 1,
          label: "VIP1",
        },
        {
          value: 2,
          label: "VIP2",
        },
        {
          value: 3,
          label: "VIP3",
        },
        {
          value: 4,
          label: "VIP4",
        },
        {
          value: 5,
          label: "VIP5",
        },
        {
          value: 6,
          label: "VIP6",
        },
        {
          value: 7,
          label: "VIP7",
        },
        {
          value: 8,
          label: "VIP8",
        },
      ],
      options: [
        {
          value: "1",
          label: "鞋靴",
        },
        {
          value: "2",
          label: "服装",
        },
        {
          value: "3",
          label: "箱包",
        },
        {
          value: "4",
          label: "手表",
        },
        {
          value: "5",
          label: "电子",
        },
        {
          value: "6",
          label: "帽子",
        },
        {
          value: "7",
          label: "饰品",
        },
        {
          value: "8",
          label: "眼镜",
        },
        {
          value: "9",
          label: "美妆",
        },
        {
          value: "10",
          label: "围巾",
        },
        {
          value: "11",
          label: "其他",
        },
      ],
      value: "",
    };
  },
  methods: {
    upqz(e) {
      console.log(e);
      var that = this;
      this.$http
        .get(
          this.$store.state.baseUrl +
            "/api/album/upqz?id=" +
            e.id +
            "&llevel=" +
            e.llevel
        )
        .then(function(res) {
          if (res.data.code == 200) {
            that.$message({
              message: "成功！",
              type: "success",
            });
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },
    //添加单条信息
    //1-https://product.aliyizhan.com/icon/market/member/90.gif
    //2  https://product.aliyizhan.com/icon/market/member/101.gif
    //3-  https://product.aliyizhan.com/icon/market/member/110.gif
    //4-https://product.aliyizhan.com/icon/market/member/120.gif
    //5- https://product.aliyizhan.com/icon/market/member/130.gif
    //6- https://product.aliyizhan.com/icon/market/member/140.gif
    //7-https://product.aliyizhan.com/icon/market/member/150.gif
    //8- https://product.aliyizhan.com/icon/market/member/160.gif
    numTlicon(num) {
      var str = "";
      switch (num) {
        case 1:
          str = "https://product.aliyizhan.com/icon/market/member/90.gif";
          break;

        case 2:
          str = "https://product.aliyizhan.com/icon/market/member/101.gif";
          break;
        case 3:
          str = "https://product.aliyizhan.com/icon/market/member/110.gif";
          break;
        case 4:
          str = "https://product.aliyizhan.com/icon/market/member/120.gif";
          break;
        case 5:
          str = "https://product.aliyizhan.com/icon/market/member/130.gif";
          break;

        case 6:
          str = "https://product.aliyizhan.com/icon/market/member/140.gif";
          break;
        case 7:
          str = "https://product.aliyizhan.com/icon/market/member/150.gif";
          break;
        case 8:
          str = "https://product.aliyizhan.com/icon/market/member/160.gif";
          break;
        default:
          str = "";
          break;
      }
      return str;
    },

    addalbum() {
      console.log(this.addvip);
      if (
        !this.addname ||
        !this.addtitle ||
        !this.addvip ||
        !this.addqq ||
        !this.jianjie
      ) {
        this.$message({
          message: "参数不全！",
          type: "warning",
        });
        return;
      }

      var data = {
        description: this.addtitle,
        licon: this.numTlicon(this.addvip),
        llevel: this.llevel,
        wxname: this.wxname,
        lname: "VIP" + this.addvip,
        Category: this.value,
        name: this.addname,
        jianjie: this.jianjie,
        cqq: this.addqq,
        logo: this.logo,
        cwx: this.cwx,
        cws: this.cws,
      };
      var that = this;
      this.$http
        .post(this.$store.state.baseUrl + "/api/album/addalbum", data)
        .then(function(res) {
          if (res.data.code == 200) {
            that.$message({
              message: "添加成功！",
              type: "success",
            });

            that.list = [];
            that.getalbumlist(15, 1, "DESC");
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },

    //上传成功
    onupsuccess1(ss) {
      // https://huoyuan.ptfish.cn/storage/image/g00MiUo8H3WdSMjijIvpboiIAV00X4MJ3tflqefv.png//实际能显示的内容
      // public/image/CwganCSFx8amplJipm2rGwW9OLgKTtjKB581lI3t.png
      if (ss) {
        this.logo = ss.split("/logo/")[1];
      }
    },
    //上传成功
    onupsuccess2(ss) {
      // https://huoyuan.ptfish.cn/storage/image/g00MiUo8H3WdSMjijIvpboiIAV00X4MJ3tflqefv.png//实际能显示的内容
      // public/image/CwganCSFx8amplJipm2rGwW9OLgKTtjKB581lI3t.png
      if (ss) {
        this.cwx = ss.split("/wx/")[1];
      }
    },
    //上传成功
    onupsuccess3(ss) {
      // https://huoyuan.ptfish.cn/storage/image/g00MiUo8H3WdSMjijIvpboiIAV00X4MJ3tflqefv.png//实际能显示的内容
      // public/image/CwganCSFx8amplJipm2rGwW9OLgKTtjKB581lI3t.png
      if (ss) {
        this.cws = ss.split("/ws/")[1];
      }
    },

    //获取数据
    // https://huoyuan.ptfish.cn/advert/querylist
    getalbumlist(limit, page, DESC) {
      var that = this;
      this.$http
        .get(
          this.$store.state.baseUrl +
            "/api/album/querylist?limit=" +
            limit +
            "&page=" +
            page +
            "&DESC=" +
            DESC +
            ""
        )
        .then(function(res) {
          if (res.data.code == 200) {
            that.list = res.data.result.list;
            console.log(that.list);
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },
    //delalbum
    // 删除数据
    delalbum(cid) {
      if (!cid) {
        this.$message({
          message: "参数不全！",
          type: "warning",
        });
        return;
      }
      var data = {
        cid: cid,
      };
      var that = this;
      this.$http
        .post(this.$store.state.baseUrl + "/api/album/delalbum", data)
        .then(function(res) {
          if (res.data.code == 200) {
            that.$message({
              message: "删除成功！",
              type: "success",
            });

            that.list = [];
            that.getalbumlist(15, 1, "DESC");
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },
  },
  created() {
    this.baseUrl = this.$store.state.baseUrl;
    this.getalbumlist(15, 1, "DESC");
  },
};
</script>
<style lang="scss">
.Page2 {
  .tt {
    height: 220px;
    background-image: url(../assets/bj.jpeg);
    background-size: contain;
    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 0.1rem;
      color: #fff;
      line-height: 220px;
    }
  }
  header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    padding-left: 20px;
    text-align: left;
  }
  .uploadbox {
    margin-top: 20px;
    display: flex;
    background-color: #fff;
    border: 1px solid skyblue;
    position: relative;

    .tou {
      font-weight: 700;
    }
    .u22 {
      // position: relative;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    .aabb {
      display: flex;
      font-size: 15px;
      .vip {
        text-align: center;
        align-items: center;
        line-height: 50px;
        margin: 0 10px;
      }
      .aa0 {
        font-size: 15px;
        width: 100px;
      }
      .aa1 {
        margin-left: 10px;
        width: 80px;
      }
      .aa2 {
        margin-left: 10px;
        width: 100px;
      }
    }
    .u1 {
      margin-top: 10px;
      width: 150px;
      height: 100px;
      margin-right: 50px;
      .el-upload-dragger {
        width: 100px;
        height: 100px;
      }
      .el-upload-dragger .el-icon-upload {
        font-size: 40px;
        line-height: 40px;
      }
    }
    .u2 {
      flex: 1;
      padding: 20px;
      .el-input {
        width: 100%;
      }

      .el-button {
        margin-top: 10px;
        width: 120px;

        height: 40px;
      }
      .aa {
        text-align-last: left;
        font-size: 18px;
        font-weight: 700;
        margin: 10px;
      }

      .block {
        margin-top: 10px;
        width: 300px;
      }
    }
  }

  .listbox {
    margin-top: 20px;
    display: flex;
    background-color: #fff;
    border: 1px solid skyblue;
    .ll {
      padding: 10px;
    }

    .ll img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .lll img {
      width: 150px;
      height: 150px;
    }
    .ll2 {
      text-align: left;
      flex: 1;
      padding-left: 20px;
      .bb1 {
        text-align: center;
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 700;
        margin: 10px;
        .licon {
          width: 60px;
          height: 20px;
        }
        .qz {
          margin-left: 20px;
        }
        .el-input {
          width: 80px;
          height: 30px;
        }
      }
      .bb {
        text-align-last: left;
        font-size: 15px;
        font-weight: 700;
        margin: 10px;
        em {
          font-size: 3px;
        }
      }
      .bb2 {
        text-align-last: left;
        font-size: 15px;
        font-weight: 700;
        margin: 10px;
        color: skyblue;
      }
      .bb3 {
        text-align-last: left;
        font-size: 15px;
        margin: 10px;
      }
      .bb33 {
        text-align-last: left;
        font-size: 15px;
        margin: 10px;
        color: rgb(255, 72, 0);
      }
      .qqwx {
        font-size: 15px;
        font-weight: 700;
        display: flex;
        .qqt {
          display: inline-block;
          color: rgb(0, 153, 255);
        }
        .wxt {
          display: inline-block;
          color: rgb(0, 255, 0);
        }
      }
      em {
        color: rgb(255, 94, 0);
      }
    }
    .ll3 {
      z-index: 99;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
