<template>
  <div class="Page3">
    <div>你好欢迎来我的Vue3</div>
  </div>
</template>
<script>
export default {
  name: "Page3",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss"></style>
