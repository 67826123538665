<template>
  <div class="Page1">
    <header>
      <h3>添加广告</h3>
    </header>

    <div class="uploadbox">
      <div class="u1">
        <el-upload
          class="upload-demo"
          drag
          :action="baseUrl + '/api/advert/upadvert'"
          multiple
          :on-success="onupsuccess"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖到此处，或<em>上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <div class="u2">
        <div class="uu2">
          <div class="uu22">
            <el-input placeholder="用户名" v-model="addname"> </el-input>
          </div>
          <div class="uu22">
            <el-input placeholder="权重" v-model="addlevel"> </el-input>
          </div>
        </div>
        <div class="uww">
          <el-input placeholder="显示标题" v-model="addtitle"> </el-input>
        </div>
        <div class="u22">
          <div class="block">
            <el-date-picker
              v-model="addendtime"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="asd">
            <div>
              <el-input placeholder="链接" v-model="addurl"> </el-input>
            </div>
          </div>

          <el-button type="primary" @click="addAdvert">提交</el-button>
        </div>
      </div>
    </div>

    <!-- 22222222222222222222222222222222222222222222 -->
    <header>
      <h3>广告列表</h3>
    </header>
    <!-- /sssssssssssss -->
    <div class="listbox" v-for="(item, index) in list" :key="index">
      <div class="ll">
        <img :src="baseUrl + '/storage/image/' + item.img" alt="" />
      </div>
      <div class="ll2">
        <div class="lll2">
          <div class="bb">用户名：</div>
          <div class="bb2">{{ item.cid }}</div>
          <div class="qz">
            <div class="qz1">权重：</div>
            <el-input
              placeholder="请输入内容"
              v-model="item.level"
              @change="upadqz(item)"
            >
            </el-input>
          </div>
        </div>

        <div class="bb">标题：</div>
        <div class="bb3">
          {{ item.title }}
        </div>
        <div class="bb">
          到期时间：<em> {{ item.endtime }}</em>
        </div>
        <div class="bb">
          链接：
          <a target="_blank" class="sh_favicon" :href="item.url">
            {{ item.url }}
          </a>
        </div>
      </div>
      <div class="ll3">
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          @click="delAdvert(item.id)"
        ></el-button>
      </div>
    </div>

    <!-- /sssssssssssss -->
  </div>
</template>
<script>
export default {
  name: "Page1",
  components: {},
  data() {
    return {
      baseUrl: "",
      addname: "",
      addtitle: "",
      addendtime: "",
      addurl: "",
      img: "",
      addlevel: "",
      list: [],
      dialogVisible: false,
    };
  },
  methods: {
    upadqz(e) {
      console.log(e);
      var that = this;
      this.$http
        .get(
          this.$store.state.baseUrl +
            "/api/addadvrt/upadqz?id=" +
            e.id +
            "&level=" +
            e.level
        )
        .then(function(res) {
          if (res.data.code == 200) {
            that.$message({
              message: "成功！",
              type: "success",
            });
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },
    //上传成功
    onupsuccess(ss) {
      // https://huoyuan.ptfish.cn/storage/image/g00MiUo8H3WdSMjijIvpboiIAV00X4MJ3tflqefv.png//实际能显示的内容
      // public/image/CwganCSFx8amplJipm2rGwW9OLgKTtjKB581lI3t.png
      if (ss) {
        this.img = ss.split("/image/")[1];
      }
    },

    //添加广告
    addAdvert() {
      if (
        !this.addname ||
        !this.addtitle ||
        !this.addurl ||
        !this.img ||
        !this.addendtime ||
        !this.addlevel
      ) {
        this.$message({
          message: "参数不全！",
          type: "warning",
        });

        return;
      }

      var data = {
        cid: this.addname,
        title: this.addtitle,
        url: this.addurl,
        level: this.addlevel,
        img: this.img,
        endtime: this.addendtime,
      };
      var that = this;
      this.$http
        .post(this.$store.state.baseUrl + "/api/advert/addadvrt", data)
        .then(function(res) {
          if (res.data.code == 200) {
            that.$message({
              message: "添加成功！",
              type: "success",
            });
            that.list = [];
            that.getadlist();
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },
    //删除广告

    delAdvert(cid) {
      if (!cid) {
        this.$message({
          message: "参数不全！",
          type: "warning",
        });
        return;
      }
      var data = {
        cid: cid,
      };
      var that = this;
      this.$http
        .post(this.$store.state.baseUrl + "/api/advert/deladvrt", data)
        .then(function(res) {
          if (res.data.code == 200) {
            that.$message({
              message: "删除成功！",
              type: "success",
            });

            that.list = [];
            that.getadlist();
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },

    //获取广告
    // https://huoyuan.ptfish.cn/advert/querylist
    getadlist() {
      var that = this;
      this.$http
        .get(this.$store.state.baseUrl + "/api/advert/querylist")
        .then(function(res) {
          if (res.data.code == 200) {
            // console.log(res);
            that.list = res.data.result.list;
          } else {
            that.$message({
              message: "服务器出错",
              type: "warning",
            });
          }
        });
    },
  },
  created() {
    this.baseUrl = this.$store.state.baseUrl;
    this.getadlist();
  },
};
</script>
<style lang="scss">
.Page1 {
  header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    padding-left: 20px;
    text-align: left;
  }
  .uploadbox {
    margin-top: 20px;
    display: flex;
    background-color: #fff;
    border: 1px solid skyblue;
    .u1 {
      padding: 20px;
      .el-upload-dragger {
        width: 150px;
        height: 150px;
      }
      .el-upload-dragger .el-icon-upload {
        font-size: 40px;
        line-height: 40px;
      }
    }
    .u2 {
      flex: 1;
      padding: 20px;
      .el-input {
        width: 100%;
      }

      .el-button {
        margin-top: 10px;
        width: 120px;
        height: 40px;
      }
      .uu2 {
        display: flex;
        .uu22 {
          margin-right: 20px;
        }
      }
      .uww {
        margin-top: 10px;
        width: 400px;
      }
      .aa {
        text-align-last: left;
        font-size: 18px;
        font-weight: 700;
        margin: 10px;
      }
      .u22 {
        display: flex;
        .asd {
          margin-top: 10px;
          margin-left: 10px;
          margin-right: 10px;
          flex: 1;
        }
      }

      .block {
        margin-top: 10px;
        width: 300px;
      }
    }
  }
  .listbox {
    margin-top: 20px;
    display: flex;
    background-color: #fff;
    border: 1px solid skyblue;
    .ll img {
      width: 320px;
      height: 180px;
    }
    .lll2 {
      display: flex;

      .qz {
        width: 200px;
        display: flex;
        font-size: 15px;
        font-weight: 700;
        align-items: center;
        margin-left: 50px;
        .qz1 {
          width: 60px;
          text-align: center;
          line-height: 40px;
        }
        .el-input {
          margin-top: 10px;
          height: 30px;
          width: 50px;
        }
      }
    }
    .ll2 {
      text-align: left;
      flex: 1;
      padding-left: 20px;
      .bb {
        text-align-last: left;
        font-size: 15px;
        font-weight: 700;
        margin: 10px;
      }
      .bb2 {
        text-align-last: left;
        font-size: 15px;
        font-weight: 700;
        margin: 10px;
        color: skyblue;
      }
      .bb3 {
        text-align-last: left;
        font-size: 15px;

        margin: 10px;
      }
      em {
        color: rgb(255, 94, 0);
      }
    }
    .ll3 {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
